var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCard',{attrs:{"accentColor":"primary"}},[_c('CCardBody',[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('CForm',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('CRow',[_c('CCol',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"rules":"required","name":"No. Usulan","readonly":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('CInput',{attrs:{"label":"No. Usulan","placeholder":"No. Usulan","invalid-feedback":errors[0],"isValid":!errors[0],"readonly":""},model:{value:(_vm.form.no_surat),callback:function ($$v) {_vm.$set(_vm.form, "no_surat", $$v)},expression:"form.no_surat"}})]}}],null,true)})],1),_c('CCol',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Tanggal Usulan"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('CInput',{attrs:{"label":"Tanggal Usulan","placeholder":"Tanggal Usulan","invalid-feedback":errors[0],"isValid":!errors[0],"readonly":""},model:{value:(_vm.form.tgl_surat),callback:function ($$v) {_vm.$set(_vm.form, "tgl_surat", $$v)},expression:"form.tgl_surat"}})]}}],null,true)})],1)],1),_c('hr'),_vm._l((_vm.form.details),function(data,index){return [(data.is_deleted == 0)?_c('fieldset',{key:index,staticClass:"form-group border p-3"},[_c('div',{key:_vm.componentKey,attrs:{"small":""}},[_c('CRow',[_c('CCol',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":"NIP - Nama"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",attrs:{"role":"group"}},[_c('label',{},[_vm._v("NIP - Nama")]),_c('v-select',{key:'select_' + _vm.componentKey + index,class:[
                            { 'is-valid': !errors[0] },
                            { 'is-invalid': errors[0] } ],attrs:{"options":data.optionsPgw,"label":"nama","filterable":false,"disabled":true},on:{"search":function($event){return _vm.onSearch(index, $event)},"input":function($event){return _vm.onChangesPegawai(index, $event)}},model:{value:(data.petugas_array),callback:function ($$v) {_vm.$set(data, "petugas_array", $$v)},expression:"data.petugas_array"}}),(errors[0])?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()],1)]}}],null,true)})],1)],1),(data.informasi_pegawai)?_c('fieldset',{staticClass:"form-group border p-3"},[_c('legend',{staticClass:"w-auto px-2"},[_vm._v("Informasi Pegawai")]),_c('CRow',[_c('CCol',{attrs:{"md":"6"}},[_c('CInput',{attrs:{"label":"Jenis Kelamin","placeholder":"","readonly":""},model:{value:(data.informasi_pegawai.jenis_kelamin),callback:function ($$v) {_vm.$set(data.informasi_pegawai, "jenis_kelamin", $$v)},expression:"data.informasi_pegawai.jenis_kelamin"}}),_c('CInput',{attrs:{"label":"Jabatan","placeholder":"","readonly":""},model:{value:(data.informasi_pegawai.nama_jabatan),callback:function ($$v) {_vm.$set(data.informasi_pegawai, "nama_jabatan", $$v)},expression:"data.informasi_pegawai.nama_jabatan"}}),_c('CInput',{attrs:{"label":"Nama Unit Kerja","placeholder":"","readonly":""},model:{value:(data.informasi_pegawai.nama_unit_kerja),callback:function ($$v) {_vm.$set(data.informasi_pegawai, "nama_unit_kerja", $$v)},expression:"data.informasi_pegawai.nama_unit_kerja"}}),_c('CInput',{attrs:{"label":"Golongan Ruang","placeholder":"","readonly":""},model:{value:(
                          data.informasi_pegawai.pangkat_golongan_ruang
                        ),callback:function ($$v) {_vm.$set(data.informasi_pegawai, "pangkat_golongan_ruang", $$v)},expression:"\n                          data.informasi_pegawai.pangkat_golongan_ruang\n                        "}})],1),_c('CCol',{attrs:{"md":"6"}},[_c('CInput',{attrs:{"label":"Gaji Pokok","placeholder":"","readonly":""},model:{value:(data.informasi_pegawai.gaji_pokok),callback:function ($$v) {_vm.$set(data.informasi_pegawai, "gaji_pokok", $$v)},expression:"data.informasi_pegawai.gaji_pokok"}}),_c('CInput',{attrs:{"label":"TMT Pangkat","placeholder":"","readonly":""},model:{value:(data.informasi_pegawai.tmt_pangkat),callback:function ($$v) {_vm.$set(data.informasi_pegawai, "tmt_pangkat", $$v)},expression:"data.informasi_pegawai.tmt_pangkat"}}),_c('CInput',{attrs:{"label":"Masa Jabatan","placeholder":"","readonly":""},model:{value:(data.informasi_pegawai.masa_jabatan),callback:function ($$v) {_vm.$set(data.informasi_pegawai, "masa_jabatan", $$v)},expression:"data.informasi_pegawai.masa_jabatan"}}),_c('CInput',{attrs:{"label":"Umur","placeholder":"","readonly":""},model:{value:(data.informasi_pegawai.umur),callback:function ($$v) {_vm.$set(data.informasi_pegawai, "umur", $$v)},expression:"data.informasi_pegawai.umur"}})],1)],1),_c('CRow',[_c('CCol',{attrs:{"md":"6"}},[_c('CInput',{attrs:{"label":"Pendidikan Baru","placeholder":"","readonly":"","value":data.pendidikan.length > 0
                            ? data.pendidikan[0]['nama_jurusan']
                            : ''}})],1),_c('CCol',{attrs:{"md":"6"}},[_c('CInput',{attrs:{"label":"Pendidikan Lama","placeholder":"","readonly":"","value":data.pendidikan.length > 1
                            ? data.pendidikan[1]['nama_jurusan']
                            : ''}})],1)],1),_c('CRow',[_c('CCol',{attrs:{"md":"12"}},[_c('CTextarea',{attrs:{"label":"Keterangan","readonly":"","rows":"9"},model:{value:(data.keterangan),callback:function ($$v) {_vm.$set(data, "keterangan", $$v)},expression:"data.keterangan"}})],1)],1)],1):_vm._e(),_c('div',{staticClass:"position-relative table-responsive"},[_c('table',{staticClass:"table table-hover table-bordered"},[_c('thead',[_c('tr',[_c('th',{staticStyle:{"vertical-align":"middle","overflow":"hidden"}},[_c('div',[_vm._v("No")])]),_c('th',{staticStyle:{"vertical-align":"middle","overflow":"hidden"}},[_c('div',[_vm._v("Jenis Dokumen")])]),_c('th',{staticStyle:{"vertical-align":"middle","overflow":"hidden"}},[_c('div',[_vm._v("Format File")])]),_c('th',{staticStyle:{"vertical-align":"middle","overflow":"hidden"}},[_c('div',[_vm._v("File Upload")])])])]),_c('tbody',{staticClass:"position-relative"},_vm._l((data.upload_detail),function(dataUpload,index2){return _c('tr',{key:index2},[_c('td',[_vm._v(_vm._s(index2 + 1))]),_c('td',[_vm._v(_vm._s(dataUpload.nama_dokumen))]),_c('td',[_vm._v(_vm._s(dataUpload.format_file))]),_c('td',{staticStyle:{"width":"20%"}},[(
                              !dataUpload.upload_dokumen &&
                              !dataUpload.loadingFile
                            )?_c('CInputFile',{attrs:{"label":"File input","isValid":true,"custom":""},on:{"change":function($event){return _vm.uploadFile($event, index, index2)}}}):_vm._e(),(dataUpload.loadingFile)?_c('CSpinner',{attrs:{"color":"success","size":"sm"}}):_vm._e(),(dataUpload.upload_dokumen)?_c('CButtonGroup',{attrs:{"size":"sm"}},[_c('CButton',{staticClass:"px-4",attrs:{"color":"success"},on:{"click":function($event){return _vm.downloadFile(index, index2)}}},[_vm._v("Download File")])],1):_vm._e()],1)])}),0)])])],1),_c('CRow',[_c('CCol',{attrs:{"md":"6"}}),_c('CCol',{attrs:{"md":"3"}},[_c('div',{staticClass:"form-group",attrs:{"role":"group"}},[_c('v-select',{attrs:{"options":_vm.optionsStatusVerifikasi,"label":"name","reduce":function (v) { return v.value; },"filterable":false},model:{value:(data.status_verifikasi),callback:function ($$v) {_vm.$set(data, "status_verifikasi", $$v)},expression:"data.status_verifikasi"}})],1)]),_c('CCol',[_c('CButtonGroup',{staticClass:"flex-row-reverse d-flex",attrs:{"size":"sm"}},[_c('CButton',{staticClass:"col-md",attrs:{"color":"success"},on:{"click":function($event){return _vm.verifikasiKenaikan(data.id, data)}}},[_vm._v("Verifikasi")])],1)],1)],1)],1):_vm._e()]}),_c('CRow',[_c('CCol',{staticClass:"text-left",attrs:{"col":"6"}},[(_vm.loading)?_c('CSpinner',{attrs:{"color":"success","size":"sm"}}):_vm._e(),_c('CButton',{staticClass:"px-4",attrs:{"color":"success"},on:{"click":function($event){return _vm.CloseForm()}}},[_vm._v(" Kembali")]),(_vm.loading)?_c('CSpinner',{attrs:{"color":"success","size":"sm"}}):_c('CButton',{staticClass:"px-4",attrs:{"color":"primary","type":"submit"}},[_vm._v("Simpan")])],1),_c('CCol',{staticClass:"text-right",attrs:{"col":"6"}})],1)],2)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }