
<template>
  <div>
    <CCard accentColor="primary">
      <CCardBody>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
          <CForm @submit.stop.prevent="handleSubmit(submit)">
            <CRow>
              <CCol md="3">
                <validation-provider
                  rules="required"
                  v-slot="{ errors }"
                  name="No. Usulan"
                  readonly
                >
                  <CInput
                    label="No. Usulan"
                    placeholder="No. Usulan"
                    v-model="form.no_surat"
                    :invalid-feedback="errors[0]"
                    :isValid="!errors[0]"
                    readonly
                  ></CInput>
                </validation-provider>
              </CCol>
              <CCol md="3">
                <validation-provider
                  rules="required"
                  v-slot="{ errors }"
                  name="Tanggal Usulan"
                >
                  <CInput
                    label="Tanggal Usulan"
                    placeholder="Tanggal Usulan"
                    v-model="form.tgl_surat"
                    :invalid-feedback="errors[0]"
                    :isValid="!errors[0]"
                    readonly
                  ></CInput>
                </validation-provider>
              </CCol>
            </CRow>
            <hr />
            <template v-for="(data, index) in form.details">
              <fieldset
                class="form-group border p-3"
                :key="index"
                v-if="data.is_deleted == 0"
              >
                <div small style :key="componentKey">
                  <CRow>
                    <CCol md="6">
                      <validation-provider
                        rules="required"
                        v-slot="{ errors }"
                        name="NIP - Nama"
                      >
                        <div role="group" class="form-group">
                          <label class>NIP - Nama</label>
                          <v-select
                            :key="'select_' + componentKey + index"
                            v-model="data.petugas_array"
                            :options="data.optionsPgw"
                            label="nama"
                            :filterable="false"
                            @search="onSearch(index, $event)"
                            @input="onChangesPegawai(index, $event)"
                            :disabled="true"
                            :class="[
                              { 'is-valid': !errors[0] },
                              { 'is-invalid': errors[0] },
                            ]"
                          ></v-select>
                          <div class="invalid-feedback" v-if="errors[0]">
                            {{ errors[0] }}
                          </div>
                        </div>
                      </validation-provider>
                    </CCol>
                  </CRow>
                  <!---->
                  <fieldset
                    class="form-group border p-3"
                    v-if="data.informasi_pegawai"
                  >
                    <legend class="w-auto px-2">Informasi Pegawai</legend>

                    <CRow>
                      <CCol md="6">
                        <CInput
                          label="Jenis Kelamin"
                          placeholder
                          readonly
                          v-model="data.informasi_pegawai.jenis_kelamin"
                        ></CInput>
                        <CInput
                          label="Jabatan"
                          placeholder
                          readonly
                          v-model="data.informasi_pegawai.nama_jabatan"
                        ></CInput>
                        <CInput
                          label="Nama Unit Kerja"
                          placeholder
                          readonly
                          v-model="data.informasi_pegawai.nama_unit_kerja"
                        ></CInput>
                        <CInput
                          label="Golongan Ruang"
                          placeholder
                          readonly
                          v-model="
                            data.informasi_pegawai.pangkat_golongan_ruang
                          "
                        ></CInput>
                      </CCol>
                      <CCol md="6">
                        <CInput
                          label="Gaji Pokok"
                          placeholder
                          readonly
                          v-model="data.informasi_pegawai.gaji_pokok"
                        ></CInput>
                        <CInput
                          label="TMT Pangkat"
                          placeholder
                          readonly
                          v-model="data.informasi_pegawai.tmt_pangkat"
                        ></CInput>
                        <CInput
                          label="Masa Jabatan"
                          placeholder
                          readonly
                          v-model="data.informasi_pegawai.masa_jabatan"
                        ></CInput>
                        <CInput
                          label="Umur"
                          placeholder
                          readonly
                          v-model="data.informasi_pegawai.umur"
                        ></CInput>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol md="6">
                        <CInput
                          label="Pendidikan Baru"
                          placeholder
                          readonly
                          :value="
                            data.pendidikan.length > 0
                              ? data.pendidikan[0]['nama_jurusan']
                              : ''
                          "
                        ></CInput>
                      </CCol>
                      <CCol md="6">
                        <CInput
                          label="Pendidikan Lama"
                          placeholder
                          readonly
                          :value="
                            data.pendidikan.length > 1
                              ? data.pendidikan[1]['nama_jurusan']
                              : ''
                          "
                        ></CInput>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol md="12">
                        <CTextarea
                          v-model="data.keterangan"
                          label="Keterangan"
                          readonly
                          rows="9"
                        />
                      </CCol>
                    </CRow>
                  </fieldset>
                  <div class="position-relative table-responsive">
                    <table class="table table-hover table-bordered">
                      <thead>
                        <tr>
                          <th
                            class
                            style="vertical-align: middle; overflow: hidden"
                          >
                            <div>No</div>
                            <!---->
                          </th>
                          <th
                            class
                            style="vertical-align: middle; overflow: hidden"
                          >
                            <div>Jenis Dokumen</div>
                            <!---->
                          </th>
                          <th
                            class
                            style="vertical-align: middle; overflow: hidden"
                          >
                            <div>Format File</div>
                            <!---->
                          </th>
                          <th
                            class
                            style="vertical-align: middle; overflow: hidden"
                          >
                            <div>File Upload</div>
                            <!---->
                          </th>
                        </tr>
                        <!---->
                      </thead>
                      <tbody class="position-relative">
                        <tr
                          v-for="(dataUpload, index2) in data.upload_detail"
                          :key="index2"
                        >
                          <td>{{ index2 + 1 }}</td>
                          <td>{{ dataUpload.nama_dokumen }}</td>
                          <td>{{ dataUpload.format_file }}</td>
                          <td style="width: 20%">
                            <CInputFile
                              label="File input"
                              @change="uploadFile($event, index, index2)"
                              :isValid="true"
                              custom
                              v-if="
                                !dataUpload.upload_dokumen &&
                                !dataUpload.loadingFile
                              "
                            />
                            <CSpinner
                              color="success"
                              size="sm"
                              v-if="dataUpload.loadingFile"
                            />
                            <CButtonGroup
                              size="sm"
                              v-if="dataUpload.upload_dokumen"
                            >
                              <CButton
                                color="success"
                                class="px-4"
                                @click="downloadFile(index, index2)"
                                >Download File</CButton
                              >
                            </CButtonGroup>
                          </td>
                        </tr>
                      </tbody>
                      <!---->
                    </table>
                    <!---->
                  </div>
                  <!---->
                </div>

                <CRow>
                  <CCol md="6"></CCol>
                  <CCol md="3">
                    <div role="group" class="form-group">
                      <v-select
                        v-model="data.status_verifikasi"
                        :options="optionsStatusVerifikasi"
                        label="name"
                        :reduce="(v) => v.value"
                        :filterable="false"
                      ></v-select>
                    </div>
                  </CCol>
                  <CCol>
                    <CButtonGroup size="sm" class="flex-row-reverse d-flex">
                      <!-- <CButton
                        class="col-md"
                        color="warning"
                        @click="cetak(data.id)"
                        >Cetak Surat</CButton
                      > -->
                      <CButton
                        class="col-md"
                        color="success"
                        @click="verifikasiKenaikan(data.id, data)"
                        >Verifikasi</CButton
                      >
                    </CButtonGroup>
                  </CCol>
                </CRow>
              </fieldset>
            </template>
            <CRow>
            <CCol col="6" class="text-left">
              <CSpinner color="success" size="sm" v-if="loading" />
              <CButton color="success" class="px-4" @click="CloseForm()">
                Kembali</CButton>
              <CSpinner color="success" size="sm" v-if="loading" />
              <CButton color="primary" class="px-4" type="submit" v-else>Simpan</CButton>
            </CCol>
            <CCol col="6" class="text-right">
              <!-- <CButton color="link" class="px-0">Forgot password?</CButton>
              <CButton color="link" class="d-lg-none">Register now!</CButton>-->
            </CCol>
          </CRow>
          </CForm>
        </ValidationObserver>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import VSelect from "vue-select";
import _ from "lodash";
import axios from "axios";

import { ValidationProvider, ValidationObserver } from "vee-validate";
export default {
  name: "FormUsulanKenaikanPangkat",
  components: {
    VSelect,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      errorMessage: null,
      optionsPgw: [],
      optionsInstansi: [],
      optionsUnitKerja: [],
      optionsJenisLayanan: [],
      formVisibility: false,
      form: {
        id_instansi: null,
        id_unit_kerja: null,
        no_surat: null,
        tgl_surat: null,
        details: [],
      },
      loading: false,
      item: null,
      componentKey: 0,
      optionsStatusVerifikasi: [
        {
          value: 1,
          name: "Disetujui",
        },
        {
          value: 2,
          name: "Berkas Tidak Lengkap",
        },
        {
          value: 3,
          name: "Tidak Memenuhi Syarat",
        },
      ],
    };
  },
  computed: {},
  mounted() {},
  watch: {
    item: function (item) {
      this.form.id_instansi = null;
      this.form.id_unit_kerja = null;
      this.form.no_surat = null;
      this.form.tgl_surat = null;
      this.optionsInstansi = [];
      this.optionsUnitKerja = [];
      this.form.details = [];
      if (item) {
        // this.form.id_instansi = item.id_instansi.toString();
        this.form.id_unit_kerja = item.id_unit_kerja;
        this.form.no_surat = item.no_surat;
        this.form.tgl_surat = item.tgl_surat;
        const array = [];
        array.push(item.mst_instansi);
        this.optionsInstansi = array;
        const array2 = [];
        array2.push(item.mst_unit_kerja);
        this.optionsUnitKerja = array2;

        item.pelayanan_detail.forEach((element) => {
          const arrayPegawai = [];
          arrayPegawai.push({
            nip: element.d_identitas.nip,
            nama: element.d_identitas.nip + " - " + element.d_identitas.nama,
          });
          this.form.details.push({
            id: element.id,
            nip: element.nip,
            ref_jenis_layanan_id: element.ref_jenis_layanan_id,
            ref_persyaratan_pelayanan_id: element.ref_persyaratan_pelayanan_id,
            upload_file: element.upload_file,
            jenis_pelayanan_array: element.ref_jenis_pelayanan,
            petugas_array: arrayPegawai,
            optionsPgw: arrayPegawai,
            informasi_pegawai: element.d_identitas.v_detail_informasi_pegawai,
            pendidikan: element.d_identitas.r_pendidikan_umum,
            keterangan: element.keterangan,
            upload_detail: element.pelayanan_detail_upload,
            is_deleted: 0,
            status_verifikasi: element.status_verifikasi,
          });
        });
      }
    },
  },
  methods: {
    async uploadFile(files, index, index2) {
      console.log(this.form.details[index]["upload_detail"]);
      const file = new FormData();
      file.append("files", files[0]);
      try {
        this.form.details[index]["upload_detail"][index2]["loadingFile"] = true;
        this.componentKey += 1;
        let { data } = await axios.post("/api/upload", file);
        this.form.details[index]["upload_detail"][index2]["upload_dokumen"] =
          data.url;
      } catch (error) {
        console.log(error);
        this.form.details[index]["upload_detail"][index2]["upload_dokumen"] =
          null;
      } finally {
        this.form.details[index]["upload_detail"][index2][
          "loadingFile"
        ] = false;
        this.componentKey += 1;
      }
    },
    downloadFile(index, index2) {
      window.open(
        this.form.details[index]["upload_detail"][index2]["upload_dokumen"],
        "_blank"
      );
    },
    deleteFile(index, index2) {
      this.form.details[index]["upload_detail"][index2]["upload_dokumen"] =
        null;
    },
    async onSearch(index, search) {
      // loading(true);
      await this.search(index, search, this);
      // loading(false);
    },
    search: _.debounce(async (index, search, vm) => {
      let options = await vm.$store.dispatch(
        "d_identitas/autocomplete",
        search
      );
      if (search) {
        requestAnimationFrame(() => {
          vm.form.details[index]["optionsPgw"] = options;
        });
      }
    }, 300),
    async searchLayanan() {
      let options = await this.$store.dispatch("jenis_layanan/view", { id: 7 });
      this.optionsJenisLayanan = options;
    },
    async submit() {
      const vm = this;
      try {
        this.loading = true;
        let { status } = this.item
          ? await this.$store.dispatch("pelayanan/update", {
              data: this.form,
              id: vm.item.id,
            })
          : await this.$store.dispatch("pelayanan/store", this.form);
        if (status >= 200 && status <= 202) {
          Swal.fire({
            title: "Sukses",
            text: "Data berhasil tersimpan!",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Tutup!",
          }).then(() => {
            if (!vm.item) {
              requestAnimationFrame(() => {
                this.$refs.observer.reset();
              });
            }
            vm.$emit("done");
          });
        }
      } catch (x) {
        console.log(x);
      } finally {
        this.loading = false;
      }
    },
    showModalFormPilih(item) {
      this.$refs.formPilih.toggle(item, this.item);
    },
    populateInput(item) {
      this.form = {
        id_instansi: null,
        id_unit_kerja: null,
        no_surat: null,
        tgl_surat: null,
        keterangan: null,
        details: [],
      };
      this.searchLayanan("", this);
      this.item = item;
    },
    async onSearchInstansi(search, loading) {
      loading(true);
      await this.searchInstansi(search, this);
      loading(false);
    },
    searchInstansi: _.debounce(async (search, vm) => {
      let options = await vm.$store.dispatch(
        "master_instansi/autocomplete",
        search
      );
      if (search) vm.optionsInstansi = options;
    }, 300),
    async onSearchUnitKerja(search, loading) {
      loading(true);
      await this.searchUnitKerja(search, this);
      loading(false);
    },
    searchUnitKerja: _.debounce(async (search, vm) => {
      let options = await vm.$store.dispatch("master_unit_kerja/autocomplete", {
        search: search,
        id_instansi: vm.form.id_instansi,
        parent: "",
      });
      if (search) vm.optionsUnitKerja = options;
    }, 300),
    addDetails() {
      this.form.details.push({
        id: null,
        nip: null,
        ref_jenis_layanan_id: null,
        ref_persyaratan_pelayanan_id: null,
        upload_file: null,
        jenis_pelayanan_array: [],
        petugas_array: [],
        optionsPgw: [],
        informasi_pegawai: null,
        upload_detail: [],
        keterangan: null,
        pendidikan: null,
        is_deleted: 0,
      });
    },
    deleteDetails(index) {
      this.form.details[index]["is_deleted"] = 1;
    },
    async verifikasiKenaikan(id, data) {
      const vm = this;
      Swal.fire({
        title: "Peringatan!",
        text: "Apa Anda yakin ingin verifikasi pegawai ini?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Verifikasi!",
      }).then(async (value) => {
        console.log(value);
        if (value.isConfirmed) {
          await this.$store.dispatch("pelayanan/verifikasi", {
            id: id,
            status_verifikasi: data.status_verifikasi,
          });
          Swal.fire({
            title: "Ok!",
            text: "Verifikasi Berhasil!",
            icon: "warning",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Ok!",
          }).then(() => {
            vm.componentKey += 1;
          });
        }
      });
    },
    onChangesPegawai(index, value) {
      this.form.details[index].informasi_pegawai =
        value.v_detail_informasi_pegawai;
      this.form.details[index].pendidikan = value.r_pendidikan_umum;
      this.form.details[index].nip = value.nip;

      const me = this;
      me.form.details[index]["upload_detail"] = [];

      this.form.details[index].ref_jenis_layanan_id =
        this.optionsJenisLayanan.id;

      this.optionsJenisLayanan.ref_persyaratan_pelayanan.forEach((element) => {
        me.form.details[index]["upload_detail"].push({
          id: null,
          upload_dokumen: null,
          ref_persyaratan_pelayanan_id: element.id,
          nama_dokumen: element.nama_dokumen,
          format_file: element.format_file,
          loadingFile: false,
        });
      });
      this.componentKey += 1;
    },
    async cetak(id) {
      window.open(
        "http://simpeg-api.nusatekno.co.id" +
          "/api/pelayanan/cetak_pencantuman_gelar?id=" +
          id,
        "_blank"
      );
    },
    CloseForm() {
      this.$emit('clicked', !this.formVisibility)
    }
  },
};
</script>